<template>
  <div class="space-y-3.5">
    <div class="w-full flex justify-center mb-6">
      <Logo :ratio="0.3" :version="2" color-blend/>
    </div>

    <NCollapseTransition :show="Boolean(message)">
      <NAlert :bordered="false" style="--n-border-radius: 0" :show-icon="false" type="info">
        <p class="font-[480] text-sm">{{ message }}</p>
      </NAlert>
    </NCollapseTransition>

    <div class="flex flex-col bg-white rounded shadow-md" style="min-width: min(28rem, calc(100vw - 2rem))">
      <h1 class="text-xl font-semibold px-6 pt-6 pb-2 leading-tight tracking-tight md:text-2xl text-gray-900">Sign in to
        your account</h1>
      <div class="flex flex-col px-6 py-4 border-slate-200/50 border-b">
        <NForm :model="form" class="flex flex-col space-y-3" @submit.prevent.stop="forgotPassword">
          <NFormItem
              label="Your Email"
              path="email"
              :feedback="errors.email"
              :show-feedback="Boolean(errors.email)"
              :validation-status="errors.email ? 'error' : 'success'"
          >
            <NInput v-model:value="form.email" :input-props="{ type: 'email' }" placeholder="Email Address" @keydown.enter.prevent/>
          </NFormItem>

          <NButton :loading="isLoading" attr-type="submit" block round size="medium" strong type="info">
            <span>Forgot Password</span>
          </NButton>
        </NForm>
      </div>
      <div class="flex justify-end px-6 py-6">
        <RouterLink :to="{name: 'Login'}"  class="text-slate-500 hover:text-slate-700 transition-colors">
          <span>Back to login</span>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import Logo from '@core/components/Logo.vue';
import axios, {AxiosError} from 'axios';
import {NAlert, NButton, NForm, NFormItem, NInput, NCollapseTransition, useMessage} from 'naive-ui';
import {reactive, ref, unref} from 'vue';

const form = reactive({email: ''});
const errors = reactive({email: null});
const message = ref();
const isLoading = ref(false);
const notification = useMessage();

const resetErrors = () => {
  errors.email = null;
};

const forgotPassword = async () => {
  if (unref(isLoading)) {
    return;
  }

  resetErrors();

  isLoading.value = true;

  try {
    const {data: {message: msg}} = await axios.request({
      method: 'POST',
      data: {email: form.email.trim().toLowerCase()},
      url: '/forgot-password',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });

    if (message) {
      message.value = msg;
      form.email = '';
    }
  } catch (e) {
    if (e instanceof AxiosError) {
      const {errors: errs, message: msg} = e.response.data ?? {};

      notification.error(msg ?? 'Request failed!');

      try {
        if (errs?.email) {
          errors.email = errs.email[0] ?? null;
        }
      } catch {}
    }
  } finally {
    isLoading.value = false;
  }
}
</script>

