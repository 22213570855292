<template>
  <div>
    <div class="w-full flex justify-center mb-6">
      <Logo :ratio="0.3" :version="2" color-blend/>
    </div>

    <NCollapseTransition :show="Boolean(message)">
      <NAlert :bordered="false" style="--n-border-radius: 0" :show-icon="false" type="info">
        <p class="font-[480] text-sm">{{ message }}</p>
      </NAlert>
    </NCollapseTransition>

    <div class="flex flex-col bg-white rounded shadow-md" style="min-width: min(28rem, calc(100vw - 2rem))">
      <h1 class="text-xl font-semibold px-6 pt-6 pb-2 leading-tight tracking-tight md:text-2xl text-gray-900">
        Reset your account password
      </h1>
      <div class="flex flex-col px-6 py-4 border-slate-200/50 border-b">
        <NForm :model="payload" class="flex flex-col space-y-3" @submit.prevent.stop="execute">
          <NFormItem label="Your Email" v-bind="formItemProp('email')">
            <NInput :value="payload.email" readonly :input-props="{ type: 'email' }" @keydown.enter.prevent/>
          </NFormItem>

          <NFormItem label="Password" v-bind="formItemProp('password')">
            <NInput v-model:value="form.password" type="password" @keydown.enter.prevent/>
          </NFormItem>
          <NFormItem label="Password" v-bind="formItemProp('password_confirmation')">
            <NInput v-model:value="form.password_confirmation" type="password" @keydown.enter.prevent/>
          </NFormItem>

          <NButton :loading="isPending" attr-type="submit" block round size="medium" strong type="info">
            <span>Reset Password</span>
          </NButton>
        </NForm>
      </div>
      <div class="flex justify-end px-6 py-6">
        <RouterLink :to="{name: 'Login'}"  class="text-slate-500 hover:text-slate-700 transition-colors">
          <span>Back to login</span>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import Logo from '@core/components/Logo.vue';
import {useFormRequest} from '@core/composable/useRequest.js';
import {webRequest} from '@core/http.js';
import {NAlert, NButton, NCollapseTransition, NForm, NFormItem, NInput} from 'naive-ui';
import {computed, ref, unref} from 'vue';
import {useRoute} from 'vue-router';

const form = ref({
  password: '',
  password_confirmation: '',
});

const route = useRoute();
const message = ref('');

const payload = computed(() => {
  return {
    ...unref(form),
    token: route.params.token,
    email: route.query.email,
  };
})

const { isPending, execute, formItemProp } = useFormRequest({
  method: 'PUT',
  url: 'password',
  request: webRequest,
  data: () => unref(payload),
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  postSuccess(response) {
    form.value = {
      password: '',
      password_confirmation: '',
    };

    if (response?.data?.message) {
      message.value = response.data.message;
    }
  },
})
</script>
