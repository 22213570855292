export const log = (...args) => {
  args.forEach(arg => console.dir(arg));
  console.trace()
}

export const warn = (...args) => {
  args.forEach(arg => console.warn(arg));
  console.trace();
}

export const wait = (seconds = 1) => new Promise(r => setTimeout(r, seconds * 1000));
