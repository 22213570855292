import {useRequest} from '@core/composable/useRequest.js';
import {log} from '@core/utils/dev.js';
import {ref, readonly, unref} from 'vue';
import { useMessage } from 'naive-ui';
import request from '@core/http.js';

/**
 *
 * @param callbackFn { (function(...args:any[]): Promise<T>) }
 * @return {{isLoading: ref<boolean>, execute: (function(...args:any[]): Promise<T|void>)}}
 */
export const useAsyncCallback = (callbackFn = () => {}) => {
  const isLoading = ref(false);

  const execute = async (...args) => {
    if (isLoading.value) {
      return;
    }

    isLoading.value = true;

    try {
      return await callbackFn(...args);
    } catch (e) {
      log(e);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading,
    execute,
  }
};

export const useAsyncPaginatedData = ({url, getParams, extraKeys = []}) => {
  const items = ref([]);
  const pagination = ref({
    page: 1,
    pageSize: 0,
    itemCount: 0,
  });

  const extraData = ref({});

  const buildParams = (page) => ({
    ...(unref(getParams)?.() ?? {}),
    page: page ?? 1,
  });

  const { isPending, isExecutedOnce, executeOnce, execute } = useRequest({
    url: () => typeof unref(url) === 'function' ? unref(url)() : String(unref(url)),
    method: 'GET',
    params: (params) => buildParams(params?.page ?? pagination.value.page ?? 1),
    postSuccess(response) {
      const {data, total, per_page, current_page} = response.data;

      extraData.value = extraKeys.reduce((acc, key) => {
        acc[key] = key in response.data ? response.data[key] : null;
        return acc;
      }, {});

      if (Array.isArray(data)) {
        items.value = data;
        pagination.value = {
          page: current_page,
          pageSize: per_page,
          itemCount: total,
        };
      }
    },
    defaultMessages: {
      failed: 'Error fetching resource!',
    }
  });

  const onPageChanged = (page) => {
    execute?.({ page: page ?? 1 });
  }

  return {
    isLoading: readonly(isPending),
    isInitialized: readonly(isExecutedOnce),
    items,
    pagination,
    execute,
    extraData,
    initialize: executeOnce,
    onPageChanged,
  };
};

/**
 *
 * @param url
 * @param getParams
 * @return {{isLoading: Ref<boolean>, response: Ref<Array<Object>>, isInitialized: Ref<boolean>, initialize: (function(): void), execute: undefined}}
 */
export const useAsyncData = ({url, getParams}) => {
  const notification = useMessage();
  const response = ref([]);
  const isInitialized = ref(false);

  const buildParams = (params = {}) => {
    return {
      ...(unref(getParams)?.() ?? {}),
      ...(params ?? {}),
    };
  };

  const {isLoading, execute} = useAsyncCallback(async (params = {}) => {
    try {
      const _url = typeof unref(url) === 'function' ? unref(url)() : String(unref(url));

      const {data} = await request.get(_url, {params: buildParams(params)});

      response.value = data;

      isInitialized.value = true;
    } catch (e) {
      notification.error('Error fetching resource!');
    }
  });

  const initialize = () => {
    if (isInitialized.value) {
      return;
    }

    execute?.();
  };

  return {
    isLoading: readonly(isLoading),
    isInitialized: readonly(isInitialized),
    response,
    execute,
    initialize,
  };
};

export default useAsyncCallback;

