import { apiRequest } from '@core/http.js';
import {log, wait} from '@core/utils/dev.js';
import {AxiosError} from 'axios';
import {useMessage} from 'naive-ui';
import {computed, ref, unref} from 'vue';

/**
 *
 * @param {?string}defaultMessage
 * @return {{notify: (function(*): void)}}
 */
const useAxiosError = (defaultMessage) => {
  const notification = useMessage();

  const notify = (e) => {
    log(e);
    if (!(e instanceof AxiosError) || e.response.status === 422) {
      return;
    }

    try {
      const message = e.response.data?.message ?? defaultMessage ?? null;

      message && notification.error(message);

    } catch {
    }
  };

  return {
    notify,
  };
};

const useValidationError = () => {
  const validationErrors = ref({});

  const setError = (key, message = null) => {
    if (!key) {
      return;
    }

    validationErrors.value[key] = message ?? null;
  };

  const clearError = (key = null) => {
    if (key) {
      setError(key);
      return;
    }

    validationErrors.value = {};
  };

  const fromAxiosError = (e) => {
    clearError();

    if (!(e instanceof AxiosError) || e.response.status !== 422) {
      return;
    }

    try {
      validationErrors.value = Object.entries(e.response.data?.errors ?? {}).reduce((errors, [field, error]) => {
        errors[field] = Array.isArray(error) ? error[0] : error;

        return errors;
      }, {});
    } catch (e) {
      log(e);
    }
  };

  const formItemErrors = computed(() => (path) => {
    const errors = {};

    for (const unrefKey in unref(validationErrors)) {
      const key = `${path}.`;
      console.error({ unrefKey, match: unrefKey.startsWith(key) })
      if (unrefKey.startsWith(key)) {
        errors[unrefKey.replace(key, '')] = unref(validationErrors)[unrefKey];
      }
    }

    return {};
  });

  const formItemProp = computed(() => (path, multiple = false) => {
    const feedback = unref(validationErrors)[path];

    if (feedback) {
      return {
        path,
        validationStatus: 'error',
        feedback,
      };
    }

    if (multiple) {
      const _vErrorKeys = Object.keys(unref(validationErrors));
      const _vErrorMatchedFirstKey = _vErrorKeys.find(k => k.startsWith(`${path}.`));

      if (_vErrorMatchedFirstKey) {
        return {
          path,
          validationStatus: 'error',
          feedback: unref(validationErrors)[_vErrorMatchedFirstKey],
        };
      }
    }

    return {
      path,
      showFeedback: false,
    };
  });

  return {
    validationErrors,
    formItemProp,
    formItemErrors,
    fromAxiosError,
    setError,
    clearError,
  };
};

/**
 * @param {string|function} url
 * @param {'POST' | 'PUT' | 'PATCH'} method
 * @param {{ success: ?string, failed: ?string }} defaultMessages
 * @param {?function(AxiosResponse,...any)|undefined} postSuccess
 * @param {?function(AxiosError, ...any)|undefined} postFailed
 * @param {null|undefined|object|function} data
 * @param {object|null} headers
 * @param {AxiosInstance|undefined} request
 * @return {{setError: function(*, null=): void, clearError: function(null=): void, validationErrors: Ref<{}>, fromAxiosError: function(*): void, formItemErrors: ComputedRef<function(*): ({{[key: string]: string|array})>, formItemProp: ComputedRef<function(*): ({feedback: *, path: *, validationStatus: string})>, isPending: Ref<boolean>, execute: (function(...[*]): Promise<void>)}}
 */
export const useFormRequest = ({
                                 url,
                                 method = 'POST',
                                 data = null,
                                 headers = {},
                                 defaultMessages = {
                                   success: null,
                                   failed: null,
                                 },
                                 postSuccess,
                                 postFailed,
                                 request,
                               }) => {
  const isPending = ref(false);
  const notification = useMessage();
  const validationError = useValidationError();
  const axiosError = useAxiosError(defaultMessages?.failed ?? null);

  const execute = async (...args) => {
    if (isPending.value) {
      return;
    }

    validationError.clearError();

    isPending.value = true;

    const req = request ?? apiRequest;

    try {
      const response = await req({
        url: typeof url === 'function' ? url() : url,
        headers: headers ?? {},
        method,
        data: typeof data === 'function' ? data() : data,
      });

      const message = response.data?.message ?? defaultMessages?.success ?? null;

      message && notification.success(message);

      if (typeof postSuccess === 'function') {
        postSuccess?.(response, ...args);
      }
    } catch (e) {
      validationError.fromAxiosError(e);
      axiosError.notify(e);

      if (e instanceof AxiosError) {
        if (typeof postFailed === 'function') {
          postFailed?.(e, ...args);
        }
      } else {
        log('Bug::', e);
      }
    } finally {
      isPending.value = false;
    }
  };

  return {
    isPending,
    execute,
    ...validationError,
  };
};

/**
 * @param {string|function} url
 * @param {'POST' | 'PUT' | 'PATCH', 'DELETE'} method
 * @param {number} sleep
 * @param {{ success: undefined|string, failed: undefined|string }} defaultMessages
 * @param {undefined|function(AxiosResponse, ...any)} postSuccess
 * @param {undefined|function(AxiosError, ...any)} postFailed
 * @param {undefined|function()} params
 * @param {AxiosInstance|null} request
 * @return {{isPending: Ref<boolean>, isExecutedOnce: Ref<boolean>, execute: (function(...[*]): Promise<void>), executeOnce: (function(...[*]): Promise<void>)}}
 */
export const useRequest = ({
                             url,
                             method = 'POST',
                             postSuccess,
                             postFailed,
                             params,
                             sleep = 0,
                             defaultMessages = {
                               success: null,
                               failed: null,
                             },
                             request,
                           }) => {
  const isPending = ref(false);
  const isExecutedOnce = ref(false);
  const requestError = useAxiosError(defaultMessages?.failed ?? null);
  const notification = useMessage();

  const execute = async (...args) => {
    if (isPending.value) {
      return;
    }

    isPending.value = true;

    await wait(sleep);

    const req = request ?? apiRequest;
    const _params = typeof unref(params) === 'function' ? unref(params)(...args) : {};

    try {
      const response = await req({
        method,
        url: typeof url === 'function' ? url() : url,
        params: _params,
      });
      isExecutedOnce.value = true;

      const message = response.data?.message ?? defaultMessages?.success ?? null;

      if (message) {
        notification.success(message);
      }

      if (typeof postSuccess === 'function') {
        postSuccess?.(response, ...args);
      }
    } catch (e) {
      requestError.notify(e);

      if (e instanceof AxiosError) {
        if (typeof postFailed === 'function') {
          postFailed?.(e, ...args);
        }
      } else {
        log('Bug::', e);
      }

    } finally {
      isPending.value = false;
    }
  };

  return {
    isPending,
    execute,
    isExecutedOnce,
    executeOnce(...args) {
      if (unref(isExecutedOnce)) {
        return;
      }

      return execute?.(...args);
    },
  };
};

