<template>
  <div>
    <div class="w-full flex justify-center mb-6">
      <Logo :ratio="0.3" :version="2" color-blend/>
    </div>
    <div class="flex flex-col bg-white rounded shadow-md" style="min-width: min(28rem, calc(100vw - 2rem))">
      <h1 class="text-xl font-semibold px-6 pt-6 pb-2 leading-tight tracking-tight md:text-2xl text-gray-900">Sign in to
        your account</h1>
      <div class="flex flex-col px-6 py-4 border-slate-200/50 border-b">
        <NForm :model="model" class="flex flex-col space-y-3" @submit.prevent.stop="login">
          <NFormItem
              label="Your Email"
              path="email"
              :feedback="errors.email"
              :show-feedback="Boolean(errors.email)"
              :validation-status="errors.email ? 'error' : 'success'"
          >
            <NInput v-model:value="model.email" :input-props="{ type: 'email' }" @keydown.enter.prevent/>
          </NFormItem>

          <NFormItem
              :feedback="errors.password"
              :show-feedback="Boolean(errors.password)"
              :validation-status="errors.password ? 'error' : 'success'"
              first
              label="Password"
              path="password"
          >
            <NInput v-model:value="model.password" type="password" @keydown.enter.prevent/>
          </NFormItem>

          <NFormItem :show-feedback="false" :show-label="false">
            <NCheckbox v-model:checked="model.remember">Remember Me</NCheckbox>
          </NFormItem>


          <NButton :loading="isLoading" attr-type="submit" block round size="medium" strong type="info">
            <span>Login</span>
          </NButton>
        </NForm>
      </div>
      <div class="flex justify-end px-6 py-6">
        <RouterLink :to="{name: 'ForgotPassword'}"  class="text-slate-500 hover:text-slate-700 transition-colors">
          <span>Having trouble signing in?</span>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import Logo from '@core/components/Logo.vue';
import useAsyncCallback from '@core/composable/useAsyncCallback.js';
import useValidationErrors from '@core/composable/useValidationErrors.js';
import axios, {AxiosError} from 'axios';
import {NButton, NCheckbox, NForm, NFormItem, NInput, useMessage} from 'naive-ui';
import {reactive} from 'vue';

const notification = useMessage();

const model = reactive({
  email: '',
  password: '',
  remember: false,
});

const {errors, clearError, fromAxiosError} = useValidationErrors(['email', 'password']);

const {isLoading, execute: login} = useAsyncCallback(async () => {
  clearError?.();

  try {
    const {data: {message, redirect}} = await axios.request({
      method: 'POST',
      data: model,
      url: '/login',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });

    notification.success(message);

    if (redirect) {
      window.location = redirect;
    }
  } catch (e) {
    fromAxiosError?.(e);

    if (e instanceof AxiosError) {
      const {message: msg, redirect} = e.response.data ?? {};

      if (e.response.status === 422) {
        notification.error('Login form is invalid.');
      } else {
        notification.error(msg);
      }

      if (redirect) {
        window.location = redirect;
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.es-bg-secondary {
  background-color: #f1f1f1;
}

.btn-primary-es {
  background: #3152a3;
  border-color: #3152a3;
}
</style>
