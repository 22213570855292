import axios, {isAxiosError} from 'axios';

const bearer = document.head.querySelector('meta[name=access-token]')?.content;
const csrf = document.head.querySelector('meta[name=csrf-token]')?.content;

const headers = {
  'X-Requested-With': 'XMLHttpRequest',
};

if (bearer) {
  headers['Authorization'] = `Bearer ${bearer}`;
}

if (csrf) {
  headers['X-CSRF-TOKEN'] = csrf;
}

const request = axios.create({
  headers,
  baseURL: '/api/',
});

export const apiRequest = request;
export const webRequest = axios.create({baseURL: '/',});

request.interceptors.response.use(null, (error) => {
  if (isAxiosError(error) && error.response.status === 401) {
    window.location = '/logout';
    return;
  }

  throw error;
});

export const setAuthorizationToken = (token) => {
  request.defaults.headers.common['Authorization'] = token;
};

export default request;
