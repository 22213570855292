import {AxiosError} from 'axios';
import {computed, ref, unref} from 'vue';

/**
 *
 * @param fields {(string|number)[]}
 */
const useValidationErrors = (fields) => {
  const errors = ref({});

  const setError = (key, message = null) => {
    if (!key) {
      return;
    }

    errors.value[key] = message ?? null;
  };

  const clearError = (key = null) => {
    if (key) {
      setError(key);
      return;
    }

    errors.value = {};
  };

  const fromAxiosError = (e) => {
    clearError();

    if (!(e instanceof AxiosError) || e.response.status !== 422) {
      return {};
    }

    try {
      const _errors = e.response.data?.errors ?? {};

      for (const field of fields) {
        errors.value[field] = Array.isArray(_errors[field]) ? _errors[field][0] : _errors[field];
      }

    } catch {}
  };

  const formItemProp = computed(() => (path) => {
    let feedback = unref(errors)[path];

    if (feedback) {
      return {
        path,
        validationStatus: 'error',
        feedback,
      };
    }

    return  {
      path,
      showFeedback: false,
    };
  });

  return {
    errors,
    formItemProp,
    fromAxiosError,
    setError,
    clearError,
  };
};

export default useValidationErrors;
