import ForgotPassword from '@guest/views/ForgotPassword.vue';
import Login from '@guest/views/Login.vue';
import Register from '@guest/views/Register.vue';
// import RequestVerificationLink from '@guest/views/RequestVerificationLink.vue';
import ResetPassword from '@guest/views/ResetPassword.vue';
import {createRouter, createWebHistory} from 'vue-router';

const isLoggedIn = document.head.querySelectorAll('meta[name=verified]').length > 0;
const isVerified = Boolean(+document.head.querySelector('meta[name=verified]')?.content);

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: 'active',
  linkActiveClass: 'active',
  routes: [
    {
      path: '/guest/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/guest/register',
      name: 'Register',
      component: Register,
    },
    {
      path: '/guest/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
    },
    {
      path: '/guest/reset-password/:token',
      name: 'ResetPassword',
      component: ResetPassword,
    },
    // {
    //   path: '/guest/email/verify',
    //   name: 'RequestVerificationLink',
    //   component: RequestVerificationLink,
    // },
    {
      path: '/guest/',
      redirect() {
        return {name: 'Login'};
      },
    },
  ],
});

router.beforeEach((to, from) => {
  if (isLoggedIn && !isVerified && to.name !== 'RequestVerificationLink') {
    return {name: 'RequestVerificationLink'};
  }
});

export default router;
