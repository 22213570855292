import {createPinia} from 'pinia';
import {createApp} from 'vue';
import GuestApp from '@guest/GuestApp.vue';
import router from '@guest/router.js';

const app = createApp(GuestApp);

app.use(router).
  use(createPinia()).
  mount('#guest-app');

