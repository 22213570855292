<template>
<div
    class="logo"
    :class="[ `logo--v${version}`, colorBlend ? 'logo--blend' : '' ]"
    v-if="dimension"
    :style="dimension"
/>
</template>

<script setup>
import { unref, computed, toRefs, warn } from 'vue';

const props = defineProps({
  version: {
    type: Number,
    default: () => 1,
    validate(value) {
      if (![1,2].includes(value)) {
        warn(`version prop must be either 1 or 2!. ${value} given.`);
        return false;
      }

      return true;
    },
  },
  ratio: {
    type: Number,
    default: 1,
  },
  colorBlend: {
    type: Boolean,
  }
});

const { version, ratio } = toRefs(props);

const dimension = computed(() => {
  switch (unref(version)) {
    case '1':
    case 1: {
      return {
        width: (66 * unref(ratio)) + 'px',
        height: (79 * unref(ratio)) + 'px',
      }
    }
    case '2':
    case 2: {
      return {
        width: (329 * unref(ratio)) + 'px',
        height: (94 * unref(ratio)) + 'px',
      }
    }
    default: {
      return null;
    }
  }
})
</script>

<style scoped lang="scss">
.logo {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  mix-blend-mode: darken;
  transition: filter 300ms ease-in;
  display: inline-block;

  &--blend {
    mix-blend-mode: color-burn;
  }

  &--v1 {
    background-image: url(/assets/images/logo.webp);
    height: 5.5rem;
    width: 100%;
    aspect-ratio: 1;
  }

  &--v2 {
    background-image: url(/assets/images/logo-transparent.png);
    height: 2rem;
    min-width: 6rem;
    //filter: contrast(0.85) brightness(0.85);
    filter: contrast(2) brightness(0.75) saturate(1.5);
  }
}
</style>
